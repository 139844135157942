import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
// import { FaPhone } from 'react-icons/fa';
import { MdPhone } from 'react-icons/md';
import logo from "../images/logo.png";
import {Nav, Navbar, Container} from 'react-bootstrap';

const Header = ({ siteTitle }) => (
  <div className="container-fluid top-container">
    <div className="row">
      <div className="col-sm">

        <Navbar bg="light" expand="lg">
          <Container>
            <Navbar.Brand><Link to="/"><img src={logo} alt="HCH" /></Link></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto main-nav-body">
                <li className="nav-item align-middle">
                  <Link className="nav-link" to="/plans">House Plans</Link>
                </li>
                <li className="nav-item align-middle">
                  <Link className="nav-link" to="/quick-move-ins">Quick Move-Ins</Link>
                </li>
                <li className="nav-item align-middle">
                  <Link className="nav-link" to="/parade">Parade</Link>
                </li>
                <li className="nav-item align-middle">
                  <Link className="nav-link" to="/communities">Communities</Link>
                </li>
                <li className="nav-item align-middle">
                  <Link className="nav-link" to="/gallery">Gallery</Link>
                </li>
                <li className="nav-item align-middle">
                  <Link className="nav-link" to="/services">Services</Link>
                </li>
                <li className="nav-item align-middle">
                  <Link className="nav-link" to="/about-us">About Us</Link>
                </li>
                <li className="nav-item align-middle">
                  <Link className="nav-link" to="/contact-us">Contact Us</Link>
                </li>

                <li className="nav-item align-middle ml-2">
                  <a className="phone-link btn btn-outline-success align-middle" href="tel:8013884091">
                    <MdPhone className="align-middle tele-icon mr-2" />
                    <span className="align-middle">(801) 388-4091</span>
                  </a>
                </li>
                {/*
                <li className="nav-item align-middle">
                  <Link className="nav-link" to="/plans">House Plans</Link>
                </li>

                <Nav.Link className="align-middle mr-2"><Link to="/plans">House Plans</Link></Nav.Link>
                <Nav.Link className="align-middle mr-2"><Link to="/communities">Communities</Link></Nav.Link>
                <Nav.Link className="align-middle mr-2"><Link to="/gallery">Gallery</Link></Nav.Link>
                <Nav.Link className="align-middle mr-2"><Link to="/services">Services</Link></Nav.Link>
                <Nav.Link className="align-middle mr-2"><Link to="/about-us">About Us</Link></Nav.Link>
                <Nav.Link className="align-middle mr-2"><Link to="/contact-us">Contact Us</Link></Nav.Link>

                <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                </NavDropdown>
                */}
              </Nav>
              {/*<Form inline>
                <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                <Button variant="outline-success">Search</Button>
              </Form>*/}
            </Navbar.Collapse>
          </Container>
        </Navbar>

      </div>
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
