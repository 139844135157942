import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = () => (
  <div className="container-fluid footer-row">
    <div className="container footer-container">
      <div className="row">
        <div className="col-sm">
          <h5>Heritage Craft Homes</h5>
          2396 N 4350 W<br />
          Plain City, UT 84404
        </div>
        <div className="col-sm">
          <h5>Working Hours</h5>
          Monday - Friday<br />
          9:00am - 3:00pm.<br />After hours by appointment.

        </div>
        <div className="col-sm">
          <h5>Connect With Us</h5>
          <div>Phone: <a href="tel:8013884091">(801) 388-4091</a></div>
          <div>Email: <a href="mailto:info@heritagecrafthomes.com">info@heritagecrafthomes.com</a></div>
          <div><a href="http://www.facebook.com/HeritageCraftRealEstate/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f" /> Follow us on Facebook</a></div>
        </div>
        <div className="col-sm">
          <h5>Site Map</h5>
          <div><Link to="/">Home</Link></div>
          <div><Link to="/services">Services</Link></div>
          <div><Link to="/plans">Plans</Link></div>
          <div><Link to="/communities">Communities</Link></div>
          <div><Link to="/gallery">Gallery</Link></div>
          <div><Link to="/about-us">About Us</Link></div>
          <div><Link to="/contact-us">Contact Us</Link></div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm">
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col-sm text-center">
          <small>&copy; Copyright Heritage Craft Homes - All Rights Reserved</small>
        </div>
      </div>
    </div>
  </div>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
